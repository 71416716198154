const getDatepickerLocale = () => {
    return {
        weekdays: {
            shorthand: [
                $t('calendar.weekdays.shorthand.sun'),
                $t('calendar.weekdays.shorthand.mon'),
                $t('calendar.weekdays.shorthand.tue'),
                $t('calendar.weekdays.shorthand.wed'),
                $t('calendar.weekdays.shorthand.thu'),
                $t('calendar.weekdays.shorthand.fri'),
                $t('calendar.weekdays.shorthand.sat'),
            ],
            longhand: [
                $t('calendar.weekdays.shorthand.sunday'),
                $t('calendar.weekdays.shorthand.monday'),
                $t('calendar.weekdays.shorthand.tuesday'),
                $t('calendar.weekdays.shorthand.wednesday'),
                $t('calendar.weekdays.shorthand.thursday'),
                $t('calendar.weekdays.shorthand.fridat'),
                $t('calendar.weekdays.shorthand.saturday'),
            ],
        },
        months: {
            shorthand: [
                $t('calendar.months.shorthand.jan'),
                $t('calendar.months.shorthand.feb'),
                $t('calendar.months.shorthand.mar'),
                $t('calendar.months.shorthand.apr'),
                $t('calendar.months.shorthand.may'),
                $t('calendar.months.shorthand.jun'),
                $t('calendar.months.shorthand.jul'),
                $t('calendar.months.shorthand.aug'),
                $t('calendar.months.shorthand.sep'),
                $t('calendar.months.shorthand.oct'),
                $t('calendar.months.shorthand.nov'),
                $t('calendar.months.shorthand.dec'),
            ],
            longhand: [
                $t('calendar.months.longhand.january'),
                $t('calendar.months.longhand.february'),
                $t('calendar.months.longhand.march'),
                $t('calendar.months.longhand.april'),
                $t('calendar.months.longhand.may'),
                $t('calendar.months.longhand.june'),
                $t('calendar.months.longhand.july'),
                $t('calendar.months.longhand.august'),
                $t('calendar.months.longhand.september'),
                $t('calendar.months.longhand.october'),
                $t('calendar.months.longhand.november'),
                $t('calendar.months.longhand.december'),
            ],
        },
        firstDayOfWeek: $t('calendar.first_day_of_week'),
        ordinal: function (nth) {
            var s = nth % 100;
            if (s > 3 && s < 21)
                return $t('calendar.ordinal.th');
            switch (s % 10) {
                case 1:
                    return $t('calendar.ordinal.st');
                case 2:
                    return $t('calendar.ordinal.nd');
                case 3:
                    return $t('calendar.ordinal.rd');
                default:
                    return $t('calendar.ordinal.th');
            }
        },
        rangeSeparator: $t('calendar.range_separator'),
        weekAbbreviation: $t('calendar.week_abbreviation'),
        scrollTitle: $t('calendar.scroll_title'),
        toggleTitle: $t('calendar.toggle_title'),
        amPM: [
            $t('calendar.am'),
            $t('calendar.pm')
        ],
        yearAriaLabel: $t('calendar.year_aria_label'),
        monthAriaLabel: $t('calendar.month_aria_label'),
        hourAriaLabel: $t('calendar.hour_aria_label'),
        minuteAriaLabel: $t('calendar.minute_aria_label'),
        time_24hr: false,
    }
}

export default getDatepickerLocale
