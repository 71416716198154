import '@resources/sw/init'

import './bootstrap'

import store from './store'
import router from './router'

import VueGtag from "vue-gtag"
import GoogleAnalytics from "./core/plugins/google-analytics"

Vue.use(VueGtag, {
    config: {
        id: window.kmenv.gaid || '',
    },
}, router)

Vue.use(GoogleAnalytics)


const app = new Vue({
    el: '#root',
    store,
    router
})
