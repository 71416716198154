import { useRegisterSW } from 'virtual:pwa-register/vue'

import Toastify from 'toastify-js/src/toastify-es.js'

import vars from '@js/vars'

const appEnv = vars.appEnv
const appVersion = vars.appVersion
const storageKey = vars.localStorageKey
const storageVersionKey = vars.localStorageVersionKey

const intervalMS = 60 * 60 * 1000

if (vars.appClearSw) {
    window.localStorage.removeItem(storageKey)
    window.localStorage.removeItem(storageVersionKey)
    window.localStorage.clear()

    if ('serviceWorker' in navigator) {

        caches.keys().then((cacheNames) => {
            cacheNames.forEach((cacheName) => {
                console.log('Removing cacheName: ', cacheName)
                caches.delete(cacheName)
            })
        })

        navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (let registration of registrations) {
                registration.unregister()
            }
        })
    }
}

if (vars.appEnv === 'production') {
    const existingVersion = window.localStorage.getItem(storageVersionKey)
    console.log('Existing App Version: ', existingVersion)

    if ('serviceWorker' in navigator) {
        // && !/localhost/.test(window.location) && !/lvh.me/.test(window.location)) {

        // Register Service Worker
        const { updateServiceWorker } = useRegisterSW({
            onOfflineReady: () => {
                Toastify({
                    text: `<div style='display: inline;'>App is ready to work offline!</div> `,
                    escapeMarkup: false,
                    gravity: 'bottom',
                    position: 'center',
                    duration: 5000,
                    className: 'km-toastify',
                }).showToast()
            },

            // Needs when registerType is 'prompt' in vite.config.js
            onNeedRefresh: () => {
                Toastify({
                    text: `<div style='display: inline;'>An update is available!</div>
                    <br>
                    <a class='do-sw-update'>Click to update and reload</a>  `,
                    escapeMarkup: false,
                    gravity: 'bottom',
                    position: 'center',
                    duration: 5000,
                    className: 'km-toastify',
                    onClick: () => {
                        Toastify({
                            text: `<div style='display: inline;'>Trying to update the application and reloading!</div> `,
                            escapeMarkup: false,
                            gravity: 'bottom',
                            position: 'center',
                            duration: 5000,
                            className: 'km-toastify',
                        }).showToast()

                        updateSW(true)

                        window.setTimeout(() => {
                            location.reload(true)
                        }, 3000)
                    },
                }).showToast()
            },

            // onRegisteredSW(swUrl, registration) {
            //     registration &&
            //         setInterval(async () => {
            //             if (!(!registration.installing && navigator)) return

            //             if ('connection' in navigator && !navigator.onLine) return

            //             const resp = await fetch(swUrl, {
            //                 cache: 'no-store',
            //                 'cache-control': 'no-cache',
            //             })

            //             if (resp?.status === 200) await registration.update()
            //         }, intervalMS)
            // },
        })

        const updateSW = (reload) => {
            updateServiceWorker(reload)
        }

        // Delete Old Version Service Worker Caches
        const pattern = new RegExp(appVersion)

        let cacheRemoved = []

        caches.keys().then((cacheNames) => {
            cacheNames.forEach((cacheName) => {
                if (!pattern.test(cacheName)) {
                    caches.delete(cacheName)
                }
            })
        })

    }

    if (appVersion !== existingVersion) {
        console.log('New App Version: ', appVersion)

        window.localStorage.removeItem(storageKey)
        window.localStorage.removeItem(storageVersionKey)
        window.localStorage.clear()

        console.log('Old Version Removed: ', existingVersion)

        window.localStorage.setItem(storageVersionKey, appVersion)
    }
} else {

    window.localStorage.removeItem(storageKey)
    window.localStorage.removeItem(storageVersionKey)
    // window.localStorage.clear()

    if ('serviceWorker' in navigator) {

        caches.keys().then((cacheNames) => {
            cacheNames.forEach((cacheName) => {
                console.log('Removing cacheName: ', cacheName)
                caches.delete(cacheName)
            })
        })

        navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (let registration of registrations) {
                registration.unregister()
            }
        })
    }
}
